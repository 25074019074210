import React from "react"
import { graphql } from "gatsby"
import { format, formatDistanceToNow } from "date-fns"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  const { markdownRemark } = data
  const {
    name,
    date_of_birth,
    date_of_death,
    services,
  } = markdownRemark.frontmatter
  const { html } = markdownRemark
  const now = format(new Date(), "EEEE, MMMM dd, yyyy")

  return (
    <Layout>
      <SEO title={name} />

      <div className="post-wrapper">
        <h2 className="text-center">{name}</h2>

        <h3 className="text-center">
          {date_of_birth ? date_of_birth + " ~ " : null}
          <span className="inline-block">{date_of_death}</span>
        </h3>

        <hr />

        <div className="flex flex-wrap">
          {services ? (
            <div className="w-full md:w-1/2 lg:w-1/3 border-4 border-purple-700 rounded-lg">
              <h3 className="bg-purple-700 text-white text-center">Services</h3>
              {services.map((service, i) => (
                <div
                  key={service.date + service.details}
                  className={
                    (now === service.date ? "bg-purple-200" : null) +
                    " service p-2"
                  }
                >
                  <h4 className="bg-purple-400 rounded py-1 px-2">
                    {service.date}
                    <small className="block">
                      <em>
                        {formatDistanceToNow(new Date(service.dateRaw), {
                          addSuffix: true,
                          includeSeconds: true,
                        })}
                      </em>
                    </small>
                  </h4>
                  <div className="whitespace-pre px-2 mt-2">
                    {service.details}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <div
            className={services ? "w-full md:w-1/2 lg:w-2/3 p-2" : "w-full"}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        date_of_death(formatString: "MMMM DD, YYYY")
        date_of_birth(formatString: "MMMM DD, YYYY")
        services {
          dateRaw: date
          date(formatString: "dddd, MMMM DD, YYYY")
          details
        }
      }
      timeToRead
    }
  }
`
